import React, { useEffect, useRef, useState } from "react";
import './App.css';
import gptLogo from "./assets/chatgpt.svg";
import addBtn from "./assets/add-30.png";
import msgIcon from "./assets/message.svg";
import home from "./assets/home.svg";
import saved from "./assets/bookmark.svg";
import rocket from "./assets/rocket.svg";
import sendBtn from "./assets/send.svg";
import userIcon from "./assets/user-icon.png";
import gptImgLogo from "./assets/chatgptLogo.svg";
import { sendMsgToOpenAI } from './openai';

function App() {

  const msgEnd = useRef(null);

  const [input, setInput] = useState("")
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const savedMsg = localStorage.getItem("messages");
    if (savedMsg != null) {
      setMessages(JSON.parse(savedMsg));
    } else {
      setMessages([
        {
          text: "Hi, Welcome to My GPT. This website is developed by <a href='https://linkedin.com/in/charchit-bansal/' target='_blank'>Charchit Bansal</a>",
          isBot: true
        }
      ]);
    }
  }, [])


  useEffect(() => {
    msgEnd.current.scrollIntoView();
    if (messages.length > 1) {
      localStorage.setItem("messages", JSON.stringify(messages));
    }
  }, [messages])

  const handleSend = async (input) => {
    const text = input;
    setInput("");
    setMessages([...messages, { text, isBot: false }])
    const res = await sendMsgToOpenAI(text);
    setMessages([
      ...messages,
      { text, isBot: false },
      { text: res, isBot: true },
    ])
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter" && input) await handleSend(input)
  }

  const handleQuery = async (query) => {
    await handleSend(query)
  }

  const handleResetChat = () => {
    setMessages([]);
    localStorage.removeItem("messages");
    window.location.reload();
  }

  const queries = [
    "Tell me a joke",
    "What is the weather today?",
    "What is the meaning of life?",
    "How do I make a chocolate cake?",
    "What are the best movies of all time?",
    "Who won the World Cup in 2018?",
    "How do I tie a tie?",
    "What is the capital of France?",
    "What is the best way to lose weight?",
    "How do I start a blog?",
    "What is the most popular song right now?",
    "What are the symptoms of COVID-19?",
    "How do I make a paper airplane?",
    "What is the highest-grossing movie of all time?",
    "How do I take care of a pet dog?",
    "What is the best way to learn a new language?",
    "What is the stock price of Apple?",
    "How do I change my password?",
    "What are the best tourist attractions in London?",
    "How do I bake homemade bread?"
  ]

  return (
    <div className="App">
      <div className="sideBar">
        
        <div className="upperSide">
          <div className="upperSideTop"><img src={gptLogo} alt="Logo" className="logo" /><span className="brand">My GPT</span></div>
          <button className="midBtn" onClick={handleResetChat}><img src={addBtn} alt="new chat" className="addBtn" />Reset Chat</button>
          <p className="popularQueries">Some popular Queries</p>
          <div className="upperSideBottom">
            {queries.map((query, i) => (
              <button className="query" key={i} onClick={() => handleQuery(query)} ><img src={msgIcon} alt="Query" />{query}</button>
            ))}
          </div>
        </div>

        <div className="lowerSide">
          <div className="listItems" ><img src={home} alt="home" className="listItemsImg" />My GPT</div>
          <div className="listItems"><img src={saved} alt="saved" className="listItemsImg" />Version: gpt-3.5-turbo</div>
          <div className="listItems"><img src={rocket} alt="Charchit Bansal" className="listItemsImg" />Developed by {" "}<a href='https://linkedin.com/in/charchit-bansal/' target='_blank' rel="noreferrer">Charchit Bansal</a></div>
        </div>

      </div>
      <div className="main">

        <div className="chats">
          {messages.map((message, i) => (
            <div key={i} className={`chat ${message.isBot && "bot"}`}>
              <img className="chatImg" src={message.isBot ? gptImgLogo : userIcon} alt="" />
              <p className="txt" dangerouslySetInnerHTML={{ __html: message.text.replace(/\n/g, '<br />') }}></p>
            </div>
          ))}
          <div ref={msgEnd} />
        </div>

        <div className="chatFooter">
          <div className="inp">
            <input
              type="text"
              placeholder="Send a message"
              value={input}
              onChange={(e) => { setInput(e.target.value) }}
              onKeyDown={handleEnter}
            />
            <button className="send" onClick={handleSend}><img src={sendBtn} alt="Send" /></button>
          </div>
          <p>My GPT may produce inaccurate information about people, places or facts. My GPT 0.01 Version.</p>
        </div>

      </div>
    </div>
  );
}

export default App;
