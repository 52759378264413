import OpenAI from 'openai';
const openai = new OpenAI({
    apiKey: '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', // My Key charchit07014902019
    dangerouslyAllowBrowser: true
});

export async function sendMsgToOpenAI(message) {
    const params = {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: message }],
        temperature: 0.7,
        max_tokens: 256,
        top_p: 1
    };
    try {
        const chatCompletion = await openai.chat.completions.create(params);
        return chatCompletion.choices[0].message.content;
    } catch (error) {

        if (error.message.startsWith("401")) { 
            return "<h1>Error - Invalid Authentication</h1><h2>We Apologize for the Inconvenience</h2><p>We're sorry, but it seems there's an issue with the authentication process, and you are encountering an error. Please rest assured that this is not a result of any mistake on your part.</p>"
        }
        if (error.message.startsWith("429")) { 
            return "<h1>Error - Quota Exceeded</h1><h2>Our Apologies!</h2><p>We regret to inform you that our service is currently experiencing high demand, and as a result, our usage quota has been exceeded. This situation is not a result of any mistake on your part.</p><h2>What This Means:</h2><p><strong>Cause:</strong> Our service has reached its maximum capacity for the current billing period, and we appreciate your active use of our platform.</p><h2>Next Steps:</h2><ol><li>Our team is actively working to address the increased demand and will resolve this issue as quickly as possible.</li><li>If you have any urgent needs or concerns, feel free to contact our support team for personalized assistance.</li></ol><p>We sincerely apologize for any inconvenience this may cause, and we appreciate your understanding as we work to improve our service to meet your needs.</p>"
        }
        if (error.message.startsWith("503")) { 
            return "<h1>Error - Service Unavailable</h1><h2>We Apologize for the Inconvenience</h2><p>It appears that our servers are currently experiencing high traffic, leading to a 503 error. We sincerely apologize for any inconvenience this may be causing you, and want to assure you that it's not a result of any action on your part.</p><h2>What This Means:</h2><p><strong>Cause:</strong> Our servers are currently overloaded due to high demand or maintenance activities, and we are working diligently to address the situation.</p><h2>Next Steps:</h2><ol><li>We are actively working to optimize our server capacity to handle the increased traffic.</li><li>Please try your request again in a few minutes. We appreciate your patience during this period.</li><li>If the issue persists or if you have urgent needs, feel free to contact our support team for personalized assistance.</li></ol><p>Once again, we apologize for any inconvenience caused, and we appreciate your understanding as we work to enhance our service and accommodate the growing demand from our valued users.</p>"
        }

        return error.message;
    }
}